.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;

  > .btn {
    position: relative;
    flex: 1 1 auto;
  }
}

.btn-group {
  --#{$prefix}btn-group-spacing: #{$ds-space-100};

  gap: var(--#{$prefix}btn-group-spacing);
}

.btn-group-vertical {
  --#{$prefix}btn-group-spacing: #{$ds-space-100};
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--#{$prefix}btn-group-spacing);

  > .btn,
  > .btn-group {
    width: 100%;
  }
}

.btn-group-narrow {
  --#{$prefix}btn-group-spacing: 0;

  // Prevent double borders when buttons are next to each other
  // > :not(.btn-check:first-child) + .btn,
  //> .btn-group:not(:first-child) {
  // margin-left: calc(#{$btn-border-width} * -1);
  // }

  @each $color, $value in $theme-colors {
    > .btn-outline-#{$color}:not(.btn-check:first-child) + .btn-outline-#{$color} {
      margin-left: calc(#{$btn-border-width} * -1);
    }
  }

  // Reset rounded corners
  > .btn:not(:last-child):not(.dropdown-toggle):not(.dropdown-toggle-split),
  > .btn.dropdown-toggle-split:first-child,
  > .btn-group:not(:last-child) > .btn {
    @include border-end-radius(0);
  }

  // The left radius should be 0 if the button is:
  // - the "third or more" child
  // - the second child and the previous element isn't `.btn-check` (making it the first child visually)
  // - part of a btn-group which isn't the first child
  > .btn:nth-child(n + 3),
  > :not(.btn-check) + .btn,
  > .btn-group:not(:first-child) > .btn {
    @include border-start-radius(0);
  }
}

// Sizing
//
// Remix the default button sizing classes into new ones for easier manipulation.

.btn-group-sm > .btn {
  @extend .btn-sm;
}
.btn-group-lg > .btn {
  @extend .btn-lg;
}

// Optional: Group multiple button groups together for a toolbar
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .input-group {
    width: auto;
  }
}
